import React, { PureComponent, useState, useCallback, useEffect } from "react";

import Link from "../LinkWithTransitionLock"
import InViewObserver from "../InViewObserver"

class Page extends PureComponent {

  constructor(props) {
    super(props);
  }
  
  componentDidMount() {

    InViewObserver()

  }

  componentWillUnmount() {}

  render() {
    return (

      <div className="content_wrap ">

        <div className="page_header">
          <h2 className="sub_title">社宅公共藝術咖啡桌 / 第二輪</h2>
          <h1 className="main_title">#公民社會</h1>
        </div>

        <article className="page_content blog_single_post_content">

          <section className="row_area_s">
            <div className="column_7">
              <figure className="figure">
                <img src={require("../../images/blog/post_2_7_2.jpg")} alt=""/>
                <figcaption className="figcaption left">組長：李慧珍<br/>組員：吳佩珊、邱俊達、樂美成</figcaption>
              </figure>
            </div>
          </section>
          <section className="row_area_s">
            <div className="column_5">
              <div className="context">
                <p>這輪傳承創新討論，成員共同關心一件事─重新定義公共藝術，並從以下幾個面向思考：</p>

                <h4>1. 對人的思考</h4>
                <p>公共藝術應關心住民，並與之互動。無論是具體藝術行動或是硬體作品，在作品產製之前應先了解住民，進行田野踏查或空間研究。互動過程中，將關注點放在居民身上，可以挖掘出在地的問題或需求，並依此基礎進行入公共藝術的規劃或設計。回應上一輪法人的討論，面對在地問題意識做出藝術產製的時候，需要有適當的機制，容納其它領域專才進入，和藝術工作者一起合作。</p>

                <h4>2. 公共藝術生活化</h4>
                <p>社宅公共藝術日常與生活化可以恰當地回應住民，為他們使用，成為地方文化情境的要素，讓公共藝術日常化、公共日常藝術化。</p>

                <h4>3. 流動的居民形塑社會住宅特性</h4>
                <p>應將三年一輪的住民流動視作社宅特性。社宅是大量公共資源的匯聚，需要適度流動，被大多數人使用、進入。</p>

                <p>小組總結，上述的問題，目前沒有具體機制可解決，因此建議重新翻轉公共藝術定義，社宅的公共藝術應考慮上述面向，來規劃如何回應這些住民的需求。</p>
                <p>傳承非只限物件、也不必然發生在固定的地點。如果人或機制因應現況開始改變，進而影響到他人，也是傳承。我們的生活被前人影響而改變，如臺灣民主的發展，形塑人人敢言的環境，這也是傳承的展現。 </p>
              </div>
            </div>
          </section>

        </article>

        <div className="page_footer">
          <Link to="/blog/post_2_8" className="next_page">
            <div className="next_title">#機制執行</div>
            <div className="next_owner">社宅公共藝術咖啡桌 / 第二輪</div>
          </Link>
        </div>

      </div>

    );
  }
}


export default Page
